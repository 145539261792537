var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-layout"},[_c('gc-header',{attrs:{"headerData":"regionManage"}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content"},[(_vm.treeOptions.length > 0)?_c('div',{staticClass:"address-tree"},[_c('div',{staticClass:"search-modal"},[_c('gc-custom-search',{attrs:{"keyWord":"区域名称","width":"500px","search":_vm.searchForm.areaName},on:{"update:search":function($event){return _vm.$set(_vm.searchForm, "areaName", $event)}}}),_c('div',{staticClass:"serach-ops"},[_c('el-button',{directives:[{name:"click-blur",rawName:"v-click-blur"}],attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查 询")]),_c('el-button',{directives:[{name:"click-blur",rawName:"v-click-blur"}],on:{"click":_vm.handleReset}},[_vm._v("重 置")])],1)],1),_c('el-tree',{ref:"regionTree",attrs:{"node-key":"id","data":_vm.treeOptions,"default-expand-all":"","highlight-current":"","expand-on-click-node":false,"icon-class":"el-icon-arrow-right","props":{
          label: 'name',
          children: 'children',
        },"filter-node-method":_vm.filterNode},on:{"node-click":function (data) { return (_vm.currentRegionInfo = data); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var node = ref.node;
return _c('div',{staticClass:"custom-tree-node"},[_c('p',[_vm._v(_vm._s(node.label))]),(
              _vm.$has('monitor:waterregion:manage:insert') ||
              _vm.$has('monitor:waterregion:manage:update') ||
              _vm.$has('monitor:waterregion:manage:delete')
            )?_c('div',{staticClass:"operate-group"},[(_vm.$has('monitor:waterregion:manage:insert'))?_c('span',{staticClass:"icon",on:{"click":function($event){$event.stopPropagation();_vm.parentId = node.data.id;
                _vm.editRegionInfo = null;
                _vm.modalVisible = true;}}},[_c('i',{staticClass:"el-icon-plus"})]):_vm._e(),(_vm.$has('monitor:waterregion:manage:update'))?_c('span',{staticClass:"icon",on:{"click":function($event){$event.stopPropagation();return _vm.handleEditAddress(node.data)}}},[_c('i',{staticClass:"el-icon-edit"})]):_vm._e(),(_vm.$has('monitor:waterregion:manage:delete'))?_c('span',{staticClass:"icon",on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteAddress(node.data)}}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e()]):_vm._e()])}}],null,false,1824389525)})],1):_vm._e(),(_vm.treeOptions.length > 0 && _vm.currentRegionInfo)?_c('div',{staticClass:"basic-address"},[_vm._m(0),_c('div',{staticClass:"value-list"},[_c('div',{staticClass:"value-list-item"},[_c('div',{staticClass:"t"},[_vm._v("操作人员")]),_c('div',{staticClass:"v"},[_vm._v(_vm._s(_vm.currentRegionInfo.staffName || "--"))])]),_c('div',{staticClass:"value-list-item"},[_c('div',{staticClass:"t"},[_vm._v("操作时间")]),_c('div',{staticClass:"v"},[_vm._v(_vm._s(_vm.currentRegionInfo.updateTime || "--"))])])])]):_vm._e(),(
        _vm.treeOptions.length === 0 && _vm.$has('monitor:waterregion:manage:init')
      )?_c('gc-empty',{attrs:{"pageType":"regionManage"},on:{"on-btn-click":_vm.handleInitRegion}}):_vm._e(),(
        _vm.treeOptions.length === 0 && !_vm.$has('monitor:waterregion:manage:init')
      )?_c('gc-empty',{attrs:{"pageType":"regionManage2"}}):_vm._e()],1),(_vm.modalVisible)?_c('OperateRegion',{attrs:{"regionInfo":_vm.editRegionInfo,"parentId":_vm.parentId},on:{"close-modal":_vm.handleCloseModal}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-title"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/icon/basic.svg"),"alt":""}}),_c('p',{staticClass:"title"},[_vm._v("基础信息")])])}]

export { render, staticRenderFns }