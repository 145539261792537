<template>
  <gc-dialog
    :title="regionInfo ? '编辑区域' : '新增区域'"
    :show.sync="visible"
    @close="$emit('close-modal')"
    @cancel="$emit('close-modal')"
    @ok="handleSubmitForm"
    width="510px"
  >
    <el-form
      :model="modelForm"
      :rules="rules"
      ref="modelForm"
      label-position="top"
      label-width="100px"
    >
      <el-form-item
        label="所属区域"
        prop="parentId"
        v-if="!(isEdit && modelForm.level == 1)"
      >
        <gc-tree-select
          v-model="modelForm.parentId"
          :options="treeOptions"
          showSearch
          :props="{ label: 'name' }"
          :disabled="isEdit"
        ></gc-tree-select>
      </el-form-item>
      <el-form-item label="区域名称" prop="name">
        <el-input
          size="small"
          v-model="modelForm.name"
          placeholder="请输入区域名称"
        ></el-input>
      </el-form-item>
    </el-form>
  </gc-dialog>
</template>

<script>
import { ruleRequired, ruleMaxLength } from "@/utils/rules";
import { apiInsertWaterRegion, apiUpdateWaterRegion } from "@/api/regionManage";
import { mapActions } from "vuex";

export default {
  name: "OperateRegion",
  props: {
    regionInfo: {
      type: Object,
      default: () => null,
    },
    parentId: [Number, String],
  },
  data() {
    return {
      visible: true,
      modelForm: {
        parentId: null,
        name: "",
      },
      rules: {
        parentId: [ruleRequired("请选择所属区域")],
        name: [ruleRequired("请输入区域名称"), ruleMaxLength(64)],
      },
    };
  },

  created() {
    this.getTreeOptions().then(() => {
      this.initRegionForm();
    });
  },
  mounted() {},

  methods: {
    ...mapActions({
      getTreeOptions: "apiCache/getTreeOptions",
    }),
    initRegionForm() {
      if (this.isEdit) {
        const { parentId, name, id, level } = this.regionInfo;
        Object.assign(this.modelForm, { parentId, name, id, level });
      } else {
        this.modelForm.parentId = this.parentId;
      }
    },

    async handleSubmitForm() {
      try {
        await this.$refs.modelForm.validate();
        const API = this.isEdit ? apiUpdateWaterRegion : apiInsertWaterRegion;
        await API(this.modelForm);
        this.$message.success(this.isEdit ? "更新区域成功" : "新增区域成功");
        this.$emit("close-modal", true);
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    isEdit() {
      return !!this.regionInfo;
    },
    treeOptions() {
      return this.$store.getters.treeOptions;
    },
  },
};
</script>
<style lang="scss" scoped></style>
